import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_GET_PERMISSIONS
} from "react-admin";
import { stringify } from "query-string";

const LOGIN_URL = process.env.REACT_APP_API_URL + "/login";
const LOGOUT_URL = process.env.REACT_APP_API_URL + "/logout";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    console.log("logging in user:", username);
    const authParams = {
      password,
      username
    };
    const request = new Request(`${LOGIN_URL}`, {
      body: `${stringify(authParams)}`,
      method: "POST",
      credentials: 'include',
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
      })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        localStorage.setItem('authenticated', true);
        localStorage.setItem('username', username);
      });
  }
  if (type === AUTH_LOGOUT) {
    // not sure about this one
    console.log("logging out user", localStorage.getItem("username"));
    const request = new Request(`${LOGOUT_URL}`, {
      method: "DELETE",
      credentials: "include",
      headers: new Headers({
        Accept: "application/json",
      })
    });
    fetch(request).then(response => {
      console.log(response);
    });
    localStorage.setItem('authenticated', false);

    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if(status === 403 || status === 401) {
      localStorage.setItem('authenticated', false);
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("authenticated")
      ? Promise.resolve()
      : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) return Promise.resolve();
  
  console.log("Unknown method:", type);
  return Promise.reject("Unknown method");
};
