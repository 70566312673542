import React from "react";
import get from "lodash/get";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";

const chipStyles = {
  chip: { margin: 4 },
};
export const SelectChipField = withStyles(chipStyles)(({ classes, ...props }) => {
    const { choices, record, source } = props;
    var id = get(record, source);
    var val;
    for (var i = 0; i < choices.length; i++) {
      if (choices[i].id === id) {
        val = choices[i].name.toUpperCase();
        break;
      }
    }
    return (
      <Chip
        className={classes.chip}
        label={val}
      />
    );

});
