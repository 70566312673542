export const FR = {
  search: "Recherche",
  info: "Info",
  title: "Titre",
  description: "Description",
  url: "URL",
  urlText: "Texte de l'URL",
  warnLevel: "Niveau d'avertissement",
  createdAt: "Créé à",
  lastModified: "Dernière modification",
  push: "Push",
  pushState: "État push",
  editInfo: "Modifier l'info",
  createInfo: "Créer d'info",
  invalidUrl:
    "Aucune URL correcte. Le protocole doit être inclus. exemple: https://google.ch",
  invalidWarnLevel: "Valeurs acceptées: 1–3",
  missingUrlText: "Ce champ est requis quand une URL ou fichier est spécifiée",
  normal: "Normal",
  priority: "Prioritaire",
  file: "Fichier",
  fileXorUrl:
    "Spécifiez l'URL ou téléchargez un fichier. Lorsqu'un fichier est téléchargé, une URL est générée automatiquement.",
};
