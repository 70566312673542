import React from "react";
import { Layout } from "react-admin";
import IvcAdminAppBar from "./IvcAdminAppBar.js";
import IvcAdminSidebar from "./IvcAdminSidebar.js";
import IvcAdminMenu from "./IvcAdminMenu.js";

const IvcAdminLayout = (props) => (
  <Layout
    {...props}
    appBar={IvcAdminAppBar}
    sidebar={IvcAdminSidebar}
    menu={IvcAdminMenu}
  />
);

export default IvcAdminLayout;
