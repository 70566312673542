import "../App.css";
import { createMuiTheme } from "@material-ui/core/styles";

export const COLORS = {
  mainBlue: "#1976d2",
  interactionsTsBlue: "#3585c5",
  interactionsBlue: "#42a5f5",
  softGreen: "#7dc77d",
  carnation: "#ed7d8a",
  textDark: "#343434",
  textLight: "#979797",
  textVeryLight: "#d0d0d0",
  white: "#fafafa",
};

const IvcAdminTheme = createMuiTheme({
  palette: {
    primary: { main: COLORS.interactionsBlue },
    secondary: { main: COLORS.mainBlue },
    error: { main: COLORS.carnation },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "Noto Sans",
  },
  overrides: {
    MuiTableRow: {
      head: {
        backgroundColor: COLORS.textVeryLight,
        "& > th ": {
          color: COLORS.textDark,
          fontWeight: "bold",
          fontSize: 14,
        },
      },
    },
    MuiTypography: {
      body1: {
        color: COLORS.textDark,
      },
    },
    MuiTableSortLabel: {
      active: {
        color: COLORS.textLight,
      },
      root: {
        "&:focus": {
          color: COLORS.textLight,
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: COLORS.white,
        "&:hover": {
          backgroundColor: COLORS.interactionsTsBlue,
        },
      },
      root: {
        color: COLORS.white,
        "&:hover": {
          backgroundColor: COLORS.interactionsTsBlue,
        },
      },
      flatPrimary: {
        "&:hover": {
          color: COLORS.interactionsTsBlue,
        },
      },
    },
    // MuiIconButton: {
    //   root: {
    //     color: COLORS.carnation,
    //   },
    // },
  },
});

export default IvcAdminTheme;
