/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

/**
 * For info update/create only, convert uploaded file to base64
 * and attach it to the `file` sent property.
 */
const addBase64FileUploadFeature = (requestHandler) => (
  type,
  resource,
  params
) => {
  if ((type === "UPDATE" || type === "CREATE") && resource === "info") {
    if (params.data.file) {
      const file = params.data.file;
      console.log(file);
      return convertFileToBase64(file).then(function (base64File) {
        return requestHandler(type, resource, {
          ...params,
          data: {
            ...params.data,
            file: { base64Data: base64File, name: file.title },
          },
        });
      });
    }
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addBase64FileUploadFeature;
