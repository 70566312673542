import React from "react";
import { Admin, Resource, Login } from "react-admin";
import dataProvider from "./dataProvider/dataprovider.js";
import addBase64FileUploadFeature from "./dataProvider/addBase64FileUpload.js";
import IvcAdminLayout from "./layout/IvcAdminLayout.js";
import IvcAdminTheme from "./layout/IvcAdminTheme.js";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { InfoList, InfoEdit, InfoCreate } from "./resources/Info";
import authProvider from "./authProvider/authProvider";
import loginBackgroundImage from "./images/blue_background.png";
import frenchMessages from 'ra-language-french';

const IvcAdminLoginPage = () => <Login backgroundImage={loginBackgroundImage} />;

const base64CapableUploadProvider = addBase64FileUploadFeature(dataProvider);

const messages = {
  'fr': frenchMessages,
};
const i18nProvider = locale => messages[locale];

const App = () => (
  <Admin
    appLayout={IvcAdminLayout}
    theme={IvcAdminTheme}
    dataProvider={base64CapableUploadProvider}
    authProvider={authProvider}
    loginPage={IvcAdminLoginPage}
    locale="fr"
    i18nProvider={i18nProvider}
  >
    <Resource
      name="info"
      list={InfoList}
      edit={InfoEdit}
      create={InfoCreate}
      icon={InfoIcon}
      options={{ label: "Info" }}
    />
  </Admin>
);
export default App;
