import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ChipField,
  Filter,
  TextInput,
  BooleanInput,
  CardActions,
  CreateButton,
  Edit,
  Create,
  SimpleForm,
  required,
  regex,
  UrlField,
  maxLength,
  RadioButtonGroupInput,
  FileInput,
  FileField,
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import { FR } from "../i8n/translations";
import { SelectChipField } from "../components/SelectChipField.js";

const InfoFilter = ({ classes, ...props }) => (
  <Filter {...props}>
    <TextInput label={FR.search} source="q" alwaysOn />
  </Filter>
);

const InfoListActions = ({ basePath, data, resource }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

const warnLevelChoices = [
  { id: "NORMAL", name: FR.normal },
  { id: "PRIORITY", name: FR.priority },
];

export const InfoList = (props) => (
  <List
    {...props}
    title={FR.info}
    filters={<InfoFilter />}
    actions={<InfoListActions />}
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="edit">
      <TextField source="title" label={FR.title} />
      <TextField source="description" label={FR.description} />
      <UrlField source="url" label={FR.url} />
      <TextField source="urlText" label={FR.urlText} />
      <SelectChipField
        source="warnLevel"
        choices={warnLevelChoices}
        label={FR.warnLevel}
      />
      <DateField
        source="createdAt"
        label={FR.createdAt}
        showTime
        locales="fr-CH"
      />
      <DateField
        source="lastModified"
        label={FR.lastModified}
        showTime
        locales="fr-CH"
      />
      <BooleanField source="push" label={FR.push} />
      <ChipField source="pushState" label={FR.pushState} />
    </Datagrid>
  </List>
);

const validateFileXorUrl = (value, allValues) => {
  if (allValues.url && allValues.file) {
    return FR.fileXorUrl;
  }
  return;
};

const validateUrl = [
  regex(
    /(https?:\/\/)(www\.)?[a-zA-Z0-9@:%._+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?/&=]*)/,
    FR.invalidUrl
  ),
  validateFileXorUrl,
  maxLength(255),
];

const validateUrlTextRequired = (value, allValues) => {
  if ((allValues.url && !value) || (allValues.file && !value)) {
    return FR.missingUrlText;
  }
  return;
};

const validateUrlText = [validateUrlTextRequired, maxLength(255)];

const validateTitle = [required(), maxLength(255)];

const formStyles = {};

const InfoForm = withStyles(formStyles)(({ classes, ...props }) => (
  <SimpleForm {...props} submitOnEnter={false} redirect="list">
    <BooleanInput source="push" defaultValue={false} label={FR.push} />
    <TextInput
      source="title"
      placeholder="(max. 255 caractères)"
      validate={validateTitle}
      label={FR.title}
    />
    <TextInput
      source="description"
      placeholder="(max. 255 caractères)"
      label={FR.description}
    />
    <TextInput
      source="url"
      placeholder="https://example.com"
      validate={validateUrl}
      type="url"
      label={FR.url}
    />
    <TextInput
      source="urlText"
      placeholder="(max. 255 caractères)"
      validate={validateUrlText}
      label={FR.urlText}
    />
    <FileInput source="file" label={FR.file} validate={validateFileXorUrl}>
      <FileField source="src" title="title" />
    </FileInput>
    <RadioButtonGroupInput
      source="warnLevel"
      choices={warnLevelChoices}
      label={FR.warnLevel}
      validate={required()}
      defaultValue="NORMAL"
    />
  </SimpleForm>
));

export const InfoEdit = (props) => (
  <Edit {...props} title={FR.editInfo} undoable={false}>
    <InfoForm />
  </Edit>
);

export const InfoCreate = (props) => (
  <Create {...props} title={FR.createInfo}>
    <InfoForm />
  </Create>
);
